const cache = new WeakMap()

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max)
}


export default {
  mounted(el) {

    let mousedown = false
    let offsetX = 0
    let offsetY = 0

    function handleMousemove(event) {
      if (mousedown) {
        console.log('move')
        let offsetParent = el.offsetParent
        let rect = offsetParent.getBoundingClientRect()
        let x = event.pageX - rect.left
        let y = event.pageY - rect.top

        el.style.left = clamp(x - offsetX, 0, rect.width - el.clientWidth) + 'px'
        el.style.top = clamp(y - offsetY, 0, rect.height - el.clientHeight) + 'px'
      }
    }

    function handleMouseUp() {
      console.log('up')
      mousedown = false
    }

    function handleMouseDown(event) {
      console.log('down')
      mousedown = true
      let rect = el.getBoundingClientRect()
      let x = event.pageX - rect.left
      let y = event.pageY - rect.top
      offsetX = x
      offsetY = y

      el.style.position = 'absolute'
      let parent = el.parentNode
      if (getComputedStyle(parent).position === 'static') {
        parent.style.position = 'relative'
      }
    }

    const fns = { handleMousemove, handleMouseUp, handleMouseDown }
    cache.set(el, fns)

    window.addEventListener('mousemove', handleMousemove)
    window.addEventListener('mouseup', handleMouseUp)
    el.addEventListener('mousedown', handleMouseDown)
  },
  beforeUnmount(el) {
    const fns = cache.get(el)
    window.removeEventListener('mousemove', fns.handleMousemove)
    window.removeEventListener('mouseup', fns.handleMouseUp)
    el.removeEventListener('mousedown', fns.handleMouseDown)
  }
}



