<template>
  <div>
    <svg-icon :icon-class="isFullscreen ? 'exit-fullscreen' : 'fullscreen'" @click="toggle" />
  </div>
</template>

<script setup>
import { useFullscreen } from '@vueuse/core'
import { onUnmounted } from 'vue';

const { isFullscreen, enter, exit, toggle } = useFullscreen();
const keyDownEventHandler = (event) => {
  if (event.key === 'F11') {
    event.preventDefault()
    toggle()
  }
}

document.addEventListener('keydown', keyDownEventHandler)
onUnmounted(() => {
  document.removeEventListener('keydown', keyDownEventHandler)
})

</script>

<style lang='scss' scoped>
.screenfull-svg {
  display: inline-block;
  cursor: pointer;
  fill: #5a5e66;
  width: 32px;
  height: 32px;
  vertical-align: 10px;
}
</style>
