
import request from '@/utils/request'
import { exist } from '@/utils'

/**
 * 新增电站基本信息
 * @param {object} station
 * @returns {Promise<AxiosResponse>}
 */
export function add(station) {
  return request({
    url: '/device/station',
    method: 'post',
    data: station
  })
}


/**
 * 修改电站基本信息
 * @param {object} station
 * @returns {Promise<AxiosResponse>}
 */
export function update(station) {
  return request({
    url: '/device/station',
    method: 'put',
    data: station
  })
}


/**
 * 删除电站基本信息
 * @param {string} stationIds
 * @returns {Promise<AxiosResponse>}
 */

export function del(stationIds) {
  return request({
    url: `/device/station/${stationIds}`,
    method: 'delete',
  })
}


/**
 * 获取电站列表
 * @param {Object} param
 * @param {string} param.province
 * @param {string} param.city
 * @param {string} param.name
 * @param {string} param.commossioningTime
 * @param {string} param.classification
 * @param {string} param.operationModel
 * @param {string} param.pageNum
 * @param {string} param.pageSize
 * @param {string} param.assetProperty
 * @returns {Promise<AxiosResponse>}
 */
export function list(param) {
  return request({
    url: '/device/station/list',
    method: 'get',
    params: param
  })
}


/**
 * 获取电站基本信息详细信息
 * @param {number} stationId 电站id
 * @returns {Promise<AxiosResponse>}
 */
export function detail(stationId) {
  return request({
    url: `/device/station/${stationId}`,
    method: 'get',
  })
}




/**
 * 获取电站名称列表
 * @returns {Promise<AxiosResponse>}
 */
export function nameList() {
  return request({
    url: `/device/station/queryNameList`,
    method: 'get',
  })
}

/**
 * 导入
 * @param {FormData} fd
 * @returns
 */
export function importList(fd) {
  return request({
    url: `/device/station/importData`,
    method: 'post',
    data: fd
  })
}

/**
 * 导出
 * @param {Object} param
 * @param {string} param.province
 * @param {string} param.city
 * @param {string} param.name
 * @param {string} param.commossioningTime
 * @param {string} param.classification
 * @param {string} param.operationModel
 * @param {string} param.assetProperty
 * @returns {Promise<AxiosResponse>}
 */
export function exportList(param) {
  return request({
    url: `/device/station/export`,
    method: 'get',
    params: param
  })
}


/**
 * 上传电站图片
 * @param {FormData} fd
 * @param {Blob} fd.stationPicture 电站图片
 * @param {string} fd.province 电站所属省份
 * @returns
 */
export function uploadImage(fd) {
  return request({
    url: `/device/station/upload`,
    method: 'post',
    data: fd
  })
}


/**
 * 电站退役
 * @param {*} stationIds
 * @returns
 */
export function changeStationState(stationIds) {
  return request({
    url: `/device/station/changeStationState/${stationIds}`,
    method: 'get',
  })
}

/**
 * 获得模板详情
 * @returns
 */
export function getTemplateList() {
  return request({
    url: `/productmng/template/list`,
    method: 'get',
  })
}


/**
 * 导入资产数据
 * @param {*} file
 * @param {*} onUploadProgress
 * @param {*} updateSupport
 * @returns
 */
export function importAssets(file, onUploadProgress, updateSupport = false) {
  const fd = new FormData()
  fd.append('file', file, file.name)
  fd.append('updateSupport', updateSupport)
  return request({
    url: `/device/station/importStations`,
    method: 'post',
    data: fd,
    onUploadProgress: onUploadProgress,
  })
}


/**
 * 导出资产数据
 * @param {*} stations
 * @param {*} sheets
 * @param {*} exportAll
 * @returns
 */
export function exportAssets(stations, sheets, exportAll) {
  return request({
    url: `/device/station/exportStations`,
    method: 'post',
    data: {
      stations,
      sheets,
      exportAll
    }
  })
}

/**
 * 获取故障录波文件列表
 * @param {*} stationId 
 * @param {*} uuid elink的uuid
 * @param {*} fileName 模糊查询
 * @returns 
 */
export function faultRecordList(stationId, uuid, fileName, pageNum, pageSize) {
  return request({
    url: `/device/fault/record/list`,
    method: 'get',
    params: {
      stationId,
      uuid,
      fileName,
      pageNum,
      pageSize,
    }
  })
}

// v2
// ============================================================================
// ============================================================================

/**
 * 查询电站下的设备
 * @param {*} stationId
 * @returns
 */
function getDevicesById(stationId) {
  if (!exist(stationId)) {
    throw new ReferenceError('电站Id不存在')
  }
  return request({
    url: `/device/v2/station/equipments/${stationId}`,
    method: 'get',
  })
}

/**
 * Retrieves a list of devices by station ID.
 *
 * @param {string} stationId - The ID of the station to retrieve devices from.
 * @throws {ReferenceError} If the station ID does not exist.
 * @return {Promise} A Promise that resolves with the retrieved list of devices.
 */
function getDevicesByIdV2(stationId) {
  if (!exist(stationId)) {
    throw new ReferenceError('电站Id不存在')
  }
  return request({
    url: `/device/v2/station/new/equipments/${stationId}`,
    method: 'get',
  })
}


/**
 * 注册设备
 * @param {*} devices
 * @returns
 */
function registerDevices(devices) {
  return request({
    url: `/device/v2/station/equipments`,
    method: 'put',
    data: devices
  })
}

/**
 * 注册设备
 * @param {*} data
 * @returns
 */
function registerDevicesV2(data) {
  return request({
    url: `/device/v2/station/new/equipments`,
    method: 'put',
    data: data
  })
}

/**
 * 导出eLink配置
 * @param {*} stationUUID 电站ID
 * @param {*} eLinkId elink主键ID
 * @param {*} eBlockNum eBlock数量
 * @param {*} eBlockType eBlock型号
 */
export function exportELinkConfig(stationUUID, eLinkId, eBlockNum, eBlockType) {
  return request({
    url: `/device/v2/station/exportELinkConfig`,
    method: 'get',
    params: {
      stationUUID, eLinkId, eBlockNum, eBlockType
    }
  })
}

/**
 * 参考需量查询接口
 * @param stationId
 * @param pageNum
 * @param pageSize
 * @param demand
 * @returns {*}
 */
export function demandList(stationId, pageNum, pageSize, demand) {
  // if (!exist(demand)) {
  //  demand = void 0
  // }
  // http://127.0.0.1:8080/device/station/demand/list
  return request({
    url: '/device/station/demand/list',
    method: 'get',
    params: {
      stationId, pageNum, pageSize, demand
    }
  })
}



export function validateStationUUID(uuid) {
  return request({
    url: 'device/station/queryStationUUID',
    method: 'get',
    params: {
      uuid
    }
  })
}



export const v2 = Object.freeze({
  getDevicesById,
  getDevicesByIdV2,
  registerDevices,
  registerDevicesV2,
})


/**
 * 维护记录查询
 * @param {*} stationId 
 * @param {*} delFlag 1查询未删除，2查询逻辑删除
 * @param {*} id 情况一：查询指定一条记录传参，情况二：如查询多条传参null
 * @param {*} pageNum 
 * @param {*} pageSize 
 * @returns 
 */
export function getMaintainList(stationId, delFlag, id, pageNum, pageSize) {
  return request({
    url: '/device/station/maintain/query',
    method: 'get',
    params: {
      stationId,
      delFlag,
      id,
      pageNum,
      pageSize
    }
  })
}

/**
 * 维护记录新增
 * @param {*} data 
 * @param {*} data.stationId
 * @param {*} data.maintainAction
 * @param {*} data.content
 * @param {*} data.remark
 * @returns 
 */
export function addMaintain(data) {
  return request({
    url: '/device/station/maintain/save',
    method: 'post',
    data
  })
}



// /device/station/maintain/update
/**
 * 维护记录修改
 * @param {*} data 
 * @param {*} data.id
 * @param {*} data.stationId
 * @param {*} data.maintainAction
 * @param {*} data.content
 * @param {*} data.remark
 * @returns 
 */
export function updateMaintain(data) {
  return request({
    url: '/device/station/maintain/update',
    method: 'put',
    data
  })
}


/**
 * 维护记录删除
 * @param {*} id 
 * @returns 
 */
export function delMaintain(id) {
  return request({
    url: `/device/station/maintain/delete`,
    method: 'delete',
    data: { id }
  })
}
