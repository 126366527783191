<template>
  <div class="g-station-select">
    <el-select v-model.number="stationId" filterable class="select-list" :style="{ width: isShow ? '250px' : '0' }" @change="onSelectChange">
      <el-option :value="station.stationId" v-for="station in stations" :label="station.name">
        <div class="flex justify-between items-center">
          <span>{{ station.name }}</span>
          <el-tag :type="station.meterModel ? 'success' : 'info'"
            style="color: var(--el-text-color-secondary);font-size: 13px;">
            {{ station.meterModel ? '实体表' : '虚拟表' }}
          </el-tag>
        </div>
      </el-option>
    </el-select>
    <el-tooltip :content="tipText">
      <div class="ml-2" @click="onSearchClick">
        <!-- <el-button :icon="Search" plain></el-button> -->
        <svg-icon icon-class="switch-station" style="font-size: 18px;color: #5a5e66;"/>
      </div>
    </el-tooltip>
  </div>
</template>
<script setup>
import useAppStore from '@/store/modules/app'
import { Search } from '@element-plus/icons-vue'
import { computed, ref } from 'vue'

const appStore = useAppStore()
const stations = computed(() => appStore.stations);
const stationId = computed({
  get: () => appStore.stationId,
  set: val => {
    appStore.setStationId(val)
  }
})

if (!stationId.value) {
  appStore.getStations()
}


const isShow = ref(true)
const tipText = computed(() => {
  return isShow.value ? '收起电站选择' : '展开电站选择'
})
function onSearchClick() {
  isShow.value = !isShow.value
}

function onSelectChange() {
  // setTimeout(() => {
  //   isShow.value = false
  // }, 500);
}


</script>

<style scoped lang="scss">
.g-station-select {
  float: right;
  position: relative;
  top: 2px;
  margin-right: 8px;
  line-height: 50px;
  display: flex;
  align-items: center;
  // width: 80px;
  
  .select-list {
    width: 0;
    transition: width 0.2s;
    overflow: hidden;
  }

  // &:hover {

  //   .select-list {
  //     width: 250px;

  //   }
  // }
}
</style>
