<template>
  <section class="app-main">
    <router-view v-slot="{ Component, route }">
      <component :is="Component" :key="route.path" />
    </router-view>
  </section>
</template>

<script setup>
import useTagsViewStore from '@/store/modules/tagsView'

const tagsViewStore = useTagsViewStore()
const route = useRoute()
// tagsViewStore.addCachedView(route)
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 100px);
  width: 100%;
  position: relative;
  // overflow-y: auto;
  // background: #F2F2F2;
  box-sizing: content-box;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.fixed-header+.app-main {
  padding-top: 60px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 94px);
  }

  .fixed-header+.app-main {
    padding-top: 94px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 17px;
  }
}
</style>
