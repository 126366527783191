import { dom } from './dom.js'


class Toast {
  static create(text, delay=1500, callback) {
    dom.selectAll('.eui-toast').remove()

    var toastDiv = dom.create('div').addClass('eui-toast')
    var toastText = dom.create('div').addClass('eui-toast__text')
    var toastCloseBtn = dom.create('div').addClass('eui-toast__close')

    toastText.html(text)
    toastDiv.append(toastText).append(toastCloseBtn)

    toastDiv.animate(100, function (p) {
      this.setStyle('opacity', p)
      this.setStyle('transform', `translate(-50%, -${50 + 50 * p}%)`)
    }, 'quadraticIn')

    setTimeout(() => {
      toastDiv.remove()
      callback && callback()
    }, delay);

    return toastDiv
  }

  static message(text, delay, callback) {
    const toastDiv = Toast.create(text, delay, callback).addClass('message')
    dom.select('body').append(toastDiv)
  }
  static success(text, delay, callback) {
    const toastDiv = Toast.create(text, delay, callback).addClass('success')
    dom.select('body').append(toastDiv)
  }
  static info(text, delay, callback) {
    const toastDiv = Toast.create(text, delay, callback).addClass('info')
    dom.select('body').append(toastDiv)
  }
  static warning(text, delay, callback) {
    const toastDiv = Toast.create(text, delay, callback).addClass('warning')
    dom.select('body').append(toastDiv)
  }
  static error(text, delay, callback) {
    const toastDiv = Toast.create(text, delay, callback).addClass('error')
    dom.select('body').append(toastDiv)
  }
}

export { Toast }

// call
// Toast.message('hello world');
// Toast.success('hello world');
// Toast.info('hello world');
// Toast.warning('hello world');
// Toast.error('hello world');
