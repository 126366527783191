<template>
  <router-view />
</template>

<script setup>
import useSettingsStore from '@/store/modules/settings'
import { handleThemeStyle } from '@/utils/theme'

function matchDark() {
  const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  if (mediaQuery.matches) {
    document.documentElement.classList.add('dark')
  }
}

matchDark()

onMounted(() => {
  nextTick(() => {
    // 初始化主题样式
    handleThemeStyle(useSettingsStore().theme)
  })
})
</script>
<style>
#nprogress .bar {
  background: var(--el-color-primary, #2866EF) !important;
}
</style>
