import request from '@/utils/request'
import { encrypt } from '@/utils/jsencrypt'

// 登录方法
export function login(username, password, code, uuid) {
  password = encrypt(password)
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/ecenter/system/auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo(params) {
  return request({
    url: '/ecenter/system/user/getInfo',
    method: 'get',
    params: params
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/ecenter/system/auth/logout',
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}
// 获取手机号验证码
export function getCodePhone(data) {
  return request({
    url: '/ecenter/system/auth/web/sms/code',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data,
    timeout: 20000
  })
}

//  手机号登录
export function phoneLogin(data) {
  return request({
    url: '/ecenter/system/auth/web/sms/login',
    method: 'post',
    data: data,
    timeout: 20000
  })
}

