import hasRole from './permission/hasRole'
import hasPermi from './permission/hasPermi'
import copyText from './common/copyText'
import draggable from './common/draggable'
import tooltip from './tooltip'

export default function directive(app){
  app.directive('hasRole', hasRole)
  app.directive('hasPermi', hasPermi)
  app.directive('copyText', copyText)
  app.directive('tooltip', tooltip)
  app.directive('draggable', draggable)
}
