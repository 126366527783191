import {
  defineStore
} from 'pinia';
import { getRouters } from '@/api/menu'
import {
  useRouter
} from "vue-router";




const router = useRouter();
let newArr = [];
export const mainStore = defineStore("mainStore", {
  state: () => {
    return {
      loginName: "",
      userInfo: {},
      routerList: [],
      currentRoute: {},
      customerList: [],
      powerStationList: [],
      entryWarehouseTypes: [],
      outOfWarehouseTypes: [],
    };
  },
  getters: {
    roleId() {
      let str = false;
      this.user.roles.forEach((item) => {
        if (
          item.roleKey == "eConcur-maintenance-admin"
        ) {
          str = true;
          return str;
        }
      });
      return str;
    },
  },
  actions: {
    setUser(user) {
      this.user = user;
    },
    /**
     * @desc 获取全部路由
     * @param {*} param0
     */
    async getRouterAll(roleId) {
      let arr = await getRouters(roleId);
      arr.data &&
        arr.data.forEach((item) => {
          if (item.path === "/") {
            newArr.push(item.children[0]);
          } else {
            newArr.push(item);
          }
        });
      this.routerList = newArr;
    },

   
  },
  persist: {
    enabled: true, // 开启数据缓存
    detached: true,
    strategies: [
      {
        //使用插件自定义存储
        key: "main_key",
        storage: localStorage,
      },
    ],
  },
});

