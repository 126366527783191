var t = `%c
######## ##     ## #### ##    ## ########      ######  ##        #######  ##     ## ########  
##       ###   ###  ##  ###   ## ##     ##    ##    ## ##       ##     ## ##     ## ##     ## 
##       #### ####  ##  ####  ## ##     ##    ##       ##       ##     ## ##     ## ##     ## 
######   ## ### ##  ##  ## ## ## ##     ##    ##       ##       ##     ## ##     ## ##     ## 
##       ##     ##  ##  ##  #### ##     ##    ##       ##       ##     ## ##     ## ##     ## 
##       ##     ##  ##  ##   ### ##     ##    ##    ## ##       ##     ## ##     ## ##     ## 
######## ##     ## #### ##    ## ########      ######  ########  #######   #######  ########`
console.log(t, 'color: #2769F3; font-size: 12px')
console.log('%c----------------------------', 'color: #104DA8;')
console.log('%c让稳定的清洁电力惠及每一个人', 'color: #104DA8; font-size: 20px')
console.log('%cReliable Clean Power for All', 'color: #104DA8;')
console.log('%c----------------------------', 'color: #104DA8;')

/* 处理打印 */
const isDev = process.env.NODE_ENV === 'development';
const log = console.log.bind(console);
window.log = console.log = function () {/*  */ };
if (isDev) {
  window.log = console.log = log;
}


/* 网站更新检测 */
async function checkSiteChange() {
  const response = await fetch('')
  const text = await response.text()
  let domparser = new DOMParser()
  let doc = domparser.parseFromString(text, "text/html")
  let newSrc = ''
  for (let i = 0; i < doc.scripts.length; i++) {
    const script = doc.scripts[i]
    if (script.src.includes('/assets/index')) {
      newSrc = script.src
    }
  }

  let oldSrc = ''
  for (let i = 0; i < document.scripts.length; i++) {
    const script = document.scripts[i]
    if (script.src.includes('/assets/index')) {
      oldSrc = script.src
    }
  }

  // console.warn('newSrc', newSrc)
  // console.warn('oldSrc', oldSrc)

  if (newSrc !== oldSrc) {
    alert('网站内容有更新')
    location.reload(true)
  }

}



// setInterval(() => {
//   checkSiteChange()
// }, 5 * 1000)
